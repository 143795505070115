import { HttpParams } from '@angular/common/http';
import { FiltroPes } from '../shared/models/filtro-pesq';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export class Filtro {
  filtros?: string;
  pagina = 0;
  itensPorPagina = 10;
  sortField: string | string[] = 'id';
  sortOrder = SortOrder.ASC;
  filtrosPes: FiltroPes[] = [];

  definirPaginacao(paginate: {
    rows?: number;
    first?: number;
    sortField?: string;
    sortOrder?: number;
  }) {
    const { rows, first, sortField, sortOrder } = paginate;

    this.pagina = (first || 0) / (rows || 0);
    this.itensPorPagina = rows ?? 20;

    this.sortField = sortField || 'id';
    this.sortOrder = sortOrder === -1 ? SortOrder.DESC : SortOrder.ASC;
  }
}

export function criarQueryParams(
  filtro: Filtro,
  isSpecification = true,
  isPaginator = true
) {
  let params = isPaginator
    ? new HttpParams()
        .set('page', filtro.pagina.toString())
        .set('size', filtro.itensPorPagina.toString())
        .set('sort', `${filtro.sortField},${filtro.sortOrder}`)
    : new HttpParams();

  if (isSpecification) {
    let search = '';
    filtro.filtrosPes.forEach((f) => {
      search += `${f.nome}${f.operadorRelacional}${f.value},`;
    });

    if (filtro.filtrosPes.length > 0) {
      search = search.substring(0, search.length - 1);
      params = params.append('search', search);
    }
  } else {
    filtro.filtrosPes.forEach((f) => {
      params = params.append(f.nome, f.value);
    });
  }

  return params;
}

export function criarFiltroParams(filtro: FiltroPes[]) {
  let params = new HttpParams();
  filtro.forEach((f) => {
    params = params.append(f.nome, f.value);
  });

  return params;
}
