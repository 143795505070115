<section class="section">
  <div class="container">
    <div class="p-grid justify-content-center">
      <div class="p-md-6 p-text-center p-mb-5">
        <h2 class="heading-section"></h2>
      </div>
    </div>
    <div class="p-grid justify-content-center">
      <div class="p-md-12 p-lg-10">
        <div class="wrap p-d-flex">
          <div class="img">
            <a href="https://chronosinfo.com.br/" target="_blank">
              <img src="assets/images/logo_login.png" />
            </a>
            <span class="p-text-center">Gestão Empresarial</span>
            <ul>
              <li>
                <i class="fa fa-sitemap signin-icon"></i> Sped Fiscal/Pis e
                Cofins
              </li>
              <li>
                <i class="fa fa-file-text-o signin-icon"></i> NF-e NFC-e CT-e
                MDF-e
              </li>
              <li>
                <i class="fa fa-outdent signin-icon"></i> Gestão na area
                Alimentícia
              </li>
              <li><i class="fa fa-user signin-icon"></i> Multi-Loja</li>
            </ul>
          </div>
          <div class="login-wrap p-p-5">
            <div class="p-d-flex">
              <div class="w-100 p-mb-4">
                <p class="social-media p-d-flex justify-content-end">
                  <a
                    href="https://wa.me/557999985542?text"
                    target="_blank"
                    title="Contate-nos pelo WhatsApp"
                    class="social-icon p-d-flex p-ai-center justify-content-center"
                  >
                    <span class="pi pi-whatsapp"></span>
                  </a>
                  <a
                    href="https://t.me/+557999985542?text"
                    target="_blank"
                    title="Contate-nos pelo Telegram"
                    class="social-icon p-d-flex p-ai-center justify-content-center"
                    ><span class="pi pi-telegram"></span>
                  </a>
                  <a
                    href="https://www.youtube.com/@chronosinfo.manager"
                    target="_blank"
                    title="Assine nosso canal do YouTube"
                    class="social-icon p-d-flex p-ai-center justify-content-center"
                    ><span class="pi pi-youtube"></span>
                  </a>
                  <a
                    href="https://www.instagram.com/chronos.info/"
                    target="_blank"
                    title="Siga a gente no Instagram"
                    class="social-icon p-d-flex p-ai-center justify-content-center"
                  >
                    <span class="pi pi-instagram"></span>
                  </a>
                </p>
              </div>
            </div>
            <div class="p-d-flex w-100" *ngIf="msg">
              <p-message severity="error" [text]="msg"></p-message>
            </div>
            <form
              class="signin-form p-fluid"
              autocomplete="off"
              [formGroup]="form"
              (submit)="login()"
            >
              <div class="form-group p-mb-4">
                <label class="label" for="name">Usuário</label>
                <input
                  type="email"
                  pInputText
                  placeholder="email"
                  name="usuario"
                  formControlName="usuario"
                  class="form-control"
                />
              </div>
              <div class="form-group p-mb-3">
                <label class="label" for="password">Senha</label>
                <input
                  type="password"
                  pInputText
                  formControlName="senha"
                  name="senha"
                  class="form-control"
                  placeholder="Senha"
                  [required]="true"
                />
              </div>

              <div class="form-group p-text-center">
                <button
                  pButton
                  style="height: 48px"
                  type="submit"
                  [disabled]="form.invalid"
                  *ngIf="!loadding"
                  label="Entrar"
                ></button>
                <p-progressSpinner
                  strokeWidth="8"
                  [style]="{ width: '50px', height: '50px' }"
                  *ngIf="loadding"
                >
                </p-progressSpinner>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
