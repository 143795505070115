import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NotAuthenticatedError } from 'src/app/seguranca/chronos-http';
import { Logger } from './logger.service';

const log = new Logger('ErrorHandlerInterceptor');

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private messageService: MessageService, private router: Router) {}

  /* Este ErrorHandlerService centraliza o tratamento de erros em
     sua aplicação Angular. Ele verifica o tipo de erro, extrai
    mensagens de erro específicas sempre que possível e utiliza
    um serviço de mensagens para exibi-las ao usuário. */

  handleError(errorResponse: HttpErrorResponse | any) {
    let msg: string =
      'Desculpe, mas ocorreu um problema inesperado. Estamos trabalhando para resolver isso o mais rápido possível. Por favor, tente novamente mais tarde.';

    if (errorResponse.status === 404) return;

    if (typeof errorResponse === 'string') {
      msg = errorResponse;
    } else if (errorResponse instanceof NotAuthenticatedError) {
      msg =
        'Sua sessão expirou! Você será redirecionado para a página de login.';
      this.router.navigate(['/login']);
    } else if (errorResponse instanceof HttpErrorResponse) {
      if (errorResponse.status === 401) {
        msg =
          'Sua sessão expirou! Você será redirecionado para a página de login.';
        this.router.navigate(['/login']);
      } else {
        msg = this.extrairMessagemError(errorResponse);
      }
    }

    console.error('Erro na requisição', errorResponse);

    this.messageService.add({
      severity: 'error',
      summary: 'Erro',
      detail: msg,
      life: 5000,
    });
  }

  private extrairMessagemError(errorResponse: HttpErrorResponse): string {
    let msg: string =
      'Desculpe, mas ocorreu um problema inesperado. Estamos trabalhando para resolver isso o mais rápido possível. Por favor, tente novamente mais tarde.';

    switch (errorResponse.status) {
      case 400:
        msg =
          'Ocorreu um erro na sua solicitação. Verifique os dados e tente novamente.';
        return errorResponse.error?.userMessage ?? msg;
      case 403:
        return 'Você não tem permissão para acessar este recurso.';
      case 406:
      case 409:
        return 'Ocorreu um erro na sua solicitação. Verifique os dados e tente novamente.';
      case 500:
        return 'Ocorreu um erro no servidor. Por favor, tente novamente mais tarde.';
      default:
        if (
          errorResponse.status >= 400 &&
          errorResponse.status <= 499 &&
          errorResponse.error
        ) {
          try {
            msg = errorResponse.error?.error ?? msg;
            log.error(
              'Detalhe do erro na requisição',
              errorResponse.error.detail
            );
            if (errorResponse.error.objects?.length) {
              msg += errorResponse.error.objects
                .map(
                  (err: any) =>
                    `\n Para o campo ${err.name}: ${err.userMessage}`
                )
                .join('');
            } else {
              msg = errorResponse.error?.userMessage ?? msg;
            }
          } catch (e) {
            log.error('Erro ao processar a resposta de erro', e);
          }
        }
        break;
    }

    return msg;
  }
}
